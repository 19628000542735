<template>
  <b-card
    no-body
    footer-bg-variant="white"
    class="h-100"
    footer-class="border-top-0 px-3"
  >
    <img
      v-if="projectThumbnail"
      class="project__thumbnail"
      :src="projectThumbnail"
    />
    <div v-else class="img__section"></div>
    <b-card-body class="pb-2 pt-1 px-3 info__section">
      <div class="text-uppercase text-muted font-weight-bold">
        {{ $t(`projects.${projectType}`) }}
      </div>
      <div>
        {{ project.name }}
      </div>
      <div v-if="showBranchInfo" class="font-weight-bold mt-1">
        {{ branch.name }}
      </div>
    </b-card-body>
    <template v-slot:footer>
      <b-row class="no-gutters date__section my-4" align-v="center">
        <b-col class="col-12">
          <b-row v-if="projectCreatedDate" class="no-gutters">
            <b-col>
              <span>{{ $t("created") }}</span>
              <span class="text-capitalize">
                {{ projectCreatedDate | date("DD MMMM YYYY") }}
              </span>
            </b-col>
            <b-col
              v-if="isProjectPending && isUserAllowedProjectHandling"
              class="col-auto"
            >
              <img
                src="./../../assets/general-icons/trash.svg"
                class="delete__icon clickable"
                @click="openDeleteConfirmationDialog"
              />
            </b-col>
          </b-row>
          <b-button
            v-if="showProjectTestUrls && isProjectActive"
            block
            class="mt-3"
            @click="openDummyModal"
          >
            {{ $t("page.respondentURL.showTestsBtn") }}
          </b-button>
          <div v-if="isClosed && projectClosedDate" class="font-weight-bold">
            <span>{{ $t("closed") }}</span>
            <span class="text-capitalize">
              {{ projectClosedDate | date("DD MMMM YYYY") }}
            </span>
          </div>
        </b-col>
      </b-row>
      <div v-if="isUserAllowedProjectHandling">
        <hr class="my-3 mx-n3" />

        <b-row
          class="no-gutters align-items-center"
          :class="{ clickable: !isProjectActive }"
          @click="openProject"
        >
          <b-col class="col-auto small text-muted mr-2">
            <img
              :src="require(`./../../assets/general-icons/${actionIcon}.svg`)"
              class="action__icon"
            />
          </b-col>
          <b-col class="text-uppercase text-muted body__2">
            {{ $t(`page.dashboard.project.${actionText}`) }}
          </b-col>
          <b-col
            v-if="!isProjectActive"
            class="text-uppercase small text-muted col-auto"
          >
            <svg
              viewBox="0 0 16 16"
              class="bi bi-three-dots-vertical action__icon mr-n1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
              />
            </svg>
          </b-col>
        </b-row>
      </div>
    </template>
    <dummy-url-modal
      v-if="showProjectTestUrls"
      :state="dummyModalState"
      :stimulusList="dummyStimulusList"
      @updateState="updateDummyModalState"
    />
  </b-card>
</template>

<script>
import Utils from "./../../Utils";
import Config from "./../../plugins/Config";
import DummyURLModal from "./../Dashboard/DummyURLModal";
export default {
  components: {
    "dummy-url-modal": DummyURLModal
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    projectStatus: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dummyModalState: false,
      dummyStimulusList: []
    };
  },
  computed: {
    isClosed() {
      return this.projectStatus === Utils.PROJECT_STATUS.CLOSED;
    },
    actionIcon() {
      switch (this.projectStatus) {
        case Utils.PROJECT_STATUS.CLOSED:
          return "dashboard-web";
        case Utils.PROJECT_STATUS.PENDING:
        case Utils.PROJECT_STATUS.ACTIVE:
        default:
          return "options";
      }
    },
    actionText() {
      switch (this.projectStatus) {
        case Utils.PROJECT_STATUS.PENDING:
          return "configPending";
        case Utils.PROJECT_STATUS.ACTIVE:
          return "analyzing";
        case Utils.PROJECT_STATUS.CLOSED:
          return "viewDashboard";
        default:
          return "";
      }
    },
    projectCreatedDate() {
      return this.project.createdAt;
      // return "2020-11-01";
    },
    projectClosedDate() {
      return this.project.closedDate;
    },
    projectThumbnail() {
      return this.project.thumbnail;
    },
    isProjectActive() {
      return this.project.status === "active";
    },
    isProjectPending() {
      return this.project.status === Utils.PROJECT_STATUS.PENDING;
    },
    showProjectTestUrls() {
      return Config.value("showProjectTestUrls") === "true";
    },
    projectType() {
      if (this.isClientCustom) {
        return "custom";
      }
      return this.project.type;
    },
    isClientCustom() {
      return (
        this.$store.getters["auth/getUserType"] === Utils.USER_TYPE.CUSTOMER
      );
    },
    userSubType() {
      return this.$store.getters["auth/getUserSubType"];
    },
    client() {
      return { ...this.$store.getters["auth/getUserAccount"] };
    },
    isMultiUser() {
      return this.$store.getters["auth/isMultiUser"];
    },
    showBranchInfo() {
      if (this.isClientCustom && this.isMultiUser) {
        if (this.userSubType === Utils.USER_SUBTYPE.ADMIN) {
          return true;
        } else if (this.client.shareProjects) {
          return true;
        }
      }
      return false;
    },
    isUserAllowedProjectHandling() {
      // custom + multiuser + researchers are not allowed to create, edit, delete a project
      if (
        this.isClientCustom &&
        this.isMultiUser &&
        this.userSubType === Utils.USER_SUBTYPE.RESEARCHER
      ) {
        return false;
      } else {
        return true;
      }
    },
    branch() {
      const branches = this.$store.getters["multiuser/getBranches"];
      return branches.find((branch) => {
        return branch._id === this.project.branchId;
      });
    },
  },
  methods: {
    updateDummyModalState(val) {
      this.dummyModalState = val;
    },
    openDummyModal() {
      this.$store
        .dispatch("project/dummyRetrieveProjectAssets", {
          projectId: this.project._id
        })
        .then(urlList => {
          this.dummyStimulusList = urlList;
          this.dummyModalState = true;
        })
        .catch(() => {})
        .finally(() => {});
    },
    openProject() {
      if (this.project.status === "pending") this.redirectToWizard();
      else if (this.project.status === "closed") {
        this.$router.push({
          name: "analytics",
          params: {
            id: this.project._id
          }
        });
      } else {
        this.$store.commit("error/SET_ERROR", {
          titleText: "dialog.error.defaultTitle",
          messageText: "dialog.error.defaultMessage"
        });
      }
    },
    async redirectToWizard() {
      try {
        const activeProjectId = this.$store.getters[
          "project/getActiveProjectId"
        ];
        const currentProjectId = this.project._id;
        if (currentProjectId !== activeProjectId) {
          this.$store.commit("loader/SET_LOADER", {});
          await this.$store.dispatch(
            "project/retrieveProjectByClientId",
            currentProjectId
          );
          await this.$store.dispatch("stimulus/retrieveProjectAssets");
          this.$store.dispatch("loader/close");
        }
        this.$store.commit("wizard/SET_ACTIVE_STEP", this.project.step);
        const wizardNextRouteName = this.$store.getters[
          "wizard/getCurrentRouteName"
        ];
        this.$router.push({
          name: wizardNextRouteName,
          params: { id: currentProjectId }
        });
      } catch (isAuthorizationError) {
        if (!isAuthorizationError) {
          this.$store.commit("error/SET_ERROR", {
            messageText: "dialog.error.pageNotFoundMessage"
          });
        }
        this.$store.dispatch("loader/close");
      }
    },
    openDeleteConfirmationDialog() {
      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "dialog.confirm.deleteProjectTitle",
        messageText: "dialog.confirm.deleteProjectMsg",
        messageParams: { projectName: this.project.name },
        confirmBtnCallback: this.deleteProject,
        type: "DELETE"
      });
    },
    deleteProject() {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("project/deleteProjectByClientId", this.project._id)
        .then(() => {
          this.$store.dispatch("project/retrieveProjectsByClientId");
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    }
  }
};
</script>

<style scoped>
.project__thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
  overflow: hidden;
}
.img__section {
  height: 150px;
  width: 100%;
  background-color: var(--red);
}
.info__section .date__section {
  min-height: 100px;
}

.action__icon {
  width: 20px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}

.delete__icon {
  width: 16px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}
</style>
