<template>
  <b-modal
    v-model="isModalVisible"
    size="lg"
    dialog-class="mt-md-5"
    content-class="rounded-0"
    footer-class="border-top-0"
    @ok="submit"
  >
    <template v-slot:modal-header>
      <h5 class="mb-0 text-uppercase text-red font-weight-bold">
        {{ $t(`projects.${projectType}`) }}
      </h5>
    </template>
    <template v-slot:default>
      <p class="font-weight-bold">
        {{ $t("page.newProject.nameNewProjectMsg") }}
      </p>
      <!-- <p class="small text-secondary">
        {{ $t("page.newProject.autoSaveMsg") }}
      </p> -->
      <b-form-input v-model="newProjectName"></b-form-input>
      <div v-if="isBranchListNotEmpty">
        <b-form-group
          class="mt-4"
          label-class="font-weight-bold"
          :label="$t('page.newProject.chooseBranchMsg')"
          label-for="branch"
        >
          <b-form-select
            v-model="newProjectBranchId"
            :options="branchList"
            value-field="_id"
            text-field="name"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>
                {{ $t("selectAnOption") }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button
        variant="link"
        class="text-dark text-decoration-none"
        @click="cancel"
        >{{ $t("button.cancel") }}</b-button
      >
      <b-button :disabled="isFormInvalid" @click="ok">{{
        $t("button.proceed")
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    modalStatus: {
      type: Boolean,
      required: true,
    },
    projectName: {
      type: String,
      required: false,
      default: "",
    },
    projectType: {
      type: String,
      required: true,
    },
    branchList: {
      type: Array,
      required: false,
      default: () => [],
    },
    projectBranchId: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    isModalVisible: {
      get() {
        return this.modalStatus;
      },
      set(modalStatus) {
        this.$emit("setModalStatus", modalStatus);
      },
    },
    newProjectName: {
      get() {
        return this.projectName;
      },
      set(projectName) {
        this.$emit("setProjectName", projectName);
      },
    },
    newProjectBranchId: {
      get() {
        return this.projectBranchId;
      },
      set(projectBranchId) {
        this.$emit("setProjectBranchId", projectBranchId);
      },
    },
    isFormInvalid() {
      if (this.isBranchListNotEmpty) {
        if (
          this.newProjectName.length === 0 ||
          this.newProjectBranchId.length === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.newProjectName.length === 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    isBranchListNotEmpty() {
      return this.branchList.length !== 0;
    },
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style scoped></style>
