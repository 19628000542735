<template>
  <div class="px-3 pt-3 p_b__3">
    <div v-if="hasProjects">
      <div
        v-for="(projectInfo, index) in projectList"
        :key="index"
        :class="{
          'bg-white': isActiveProjectList(projectInfo.status),
          'mx-n3': isActiveProjectList(projectInfo.status),
          'px-3': isActiveProjectList(projectInfo.status)
        }"
        class="py-5"
      >
        <div class="title__1">
          {{ $t(`page.dashboard.project.status.${projectInfo.label}.title`) }}
        </div>
        <p class="text-muted subtitle__1">
          {{
            $t(`page.dashboard.project.status.${projectInfo.label}.description`)
          }}
        </p>
        <div
          v-if="isListEmpty(projectInfo.list)"
          class="font-italic text-uppercase"
          v-html="
            $t(`page.dashboard.project.status.${projectInfo.label}.emptyList`)
          "
        ></div>
        <b-row v-else class="no-gutters">
          <b-col
            v-for="project in projectInfo.list"
            :key="project.projectId"
            class="col-12 col-sm-6 col-md-4 col-lg-2 pr-0 pr-sm-3 mb-3 project__list__item"
          >
            <project-dashboard-list-item
              :project="project"
              :projectStatus="projectInfo.status"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-else class="mt-5 text-center">
      <h3 class="mb-3">{{ $t("page.projects.noPublishedProjects.title") }}</h3>
      <div v-if="isUserAllowedProjectHandling">
        <h6 class="mb-0" style="font-weight: 300">
          {{ $t("page.projects.noPublishedProjects.subtitle-1") }}
        </h6>
        <h6 class="mb-0" style="font-weight: 300">
          {{ $t("page.projects.noPublishedProjects.subtitle-2") }}
        </h6>
        <b-button
          class="text-uppercase create-project-btn mt-5"
          size="sm"
          variant="red"
          @click="hanldeCreateProjectAction"
        >
          {{ $t("button.createNewProject") }}
        </b-button>
      </div>
    </div>
    <new-project-modal
      :modalStatus="modalStatus"
      :projectName="projectName"
      :projectType="projectType"
      :branchList="isMultiuserAdmin ? branchList : []"
      :projectBranchId="projectBranchId"
      @setModalStatus="setModalStatus"
      @setProjectName="setProjectName"
      @setProjectBranchId="setProjectBranchId"
      @submit="checkEnv"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import Utils from "./../../Utils";
import ProjectDashboardListItem from "./ProjectDashboardListItem";
import NewProjectModal from "./../NewProject/NewProjectModal";
export default {
  components: {
    ProjectDashboardListItem,
    NewProjectModal,
  },
  data() {
    return {
      modalStatus: false,
      projectName: "",
      projectBranchId: "",
      projectType: "custom",
    };
  },
  computed: {
    userType() {
      return this.$store.getters["auth/getUserType"];
    },
    userSubType() {
      return this.$store.getters["auth/getUserSubType"];
    },
    isMultiUser() {
      return this.$store.getters["auth/isMultiUser"];
    },
    audienceProjectCategories() {
      return this.$store.getters["audience/getBrandCategoryOptions"];
    },
    projectList() {
      const projectList = this.$store.getters["project/getProjectList"];
      const sortedProjects = projectList.map((projectGroup) => {
        const list = [...projectGroup.list].sort((projectA, projectB) => {
          if (dayjs(projectA.createdAt).isBefore(projectB.createdAt)) return 1;
          else return -1;
        });

        return {
          ...projectGroup,
          list,
        };
      });

      return sortedProjects;
    },
    isMultiuserAdmin() {
      if (
        this.userType === Utils.USER_TYPE.CUSTOMER &&
        this.isMultiUser &&
        this.userSubType === Utils.USER_SUBTYPE.ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    branchList() {
      return this.$store.getters["multiuser/getBranches"];
    },
    hasProjects() {
      let hasEntries = false;
      for (const key in this.projectList) {
        if (this.projectList[key].list.length > 0) {
          hasEntries = true;
          break;
        }
      }
      return hasEntries;
    },
    isUserAllowedProjectHandling() {
      // custom + multiuser + researchers are not allowed to create, edit, delete a project
      if (
        this.userType === Utils.USER_TYPE.CUSTOMER &&
        this.isMultiUser &&
        this.userSubType === Utils.USER_SUBTYPE.RESEARCHER
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    isListEmpty(list) {
      return list.length === 0;
    },
    isActiveProjectList(status) {
      return status === Utils.PROJECT_STATUS.ACTIVE;
    },
    hanldeCreateProjectAction() {
      if (this.userType === Utils.USER_TYPE.REGULAR)
        this.$router.push({ name: "NewProject" });
      else if (this.userType === Utils.USER_TYPE.CUSTOMER) this.openModal();
    },
    async checkEnv() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        if (this.audienceProjectCategories.length === 0) {
          await this.$store.dispatch(
            "audience/retrieveProjectCategories",
            false
          );
        }

        this.createCutomerNewProject();
      } catch {
        this.$store.commit("error/SET_ERROR", {
          messageText: "page.newProject.error.createProject.message"
        });
      } finally {
        this.$store.dispatch("loader/close");
      }
    },
    createCutomerNewProject() {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store.commit(
        "wizard/SET_ACTIVE_PROJECT_PACKAGE",
        Utils.PROJECT_PACKAGE.CUSTOM
      );
      this.$store.commit("wizard/SET_ACTIVE_PROJECT_NAME", this.projectName);
      this.$store.commit("audience/RESET");
      this.$store.commit("question/RESET");

      this.$store
        .dispatch("project/createProjectByClientId", this.projectBranchId)
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    openModal() {
      this.modalStatus = true;
      this.projectName =
        this.projectType.toUpperCase() +
        " - " +
        this.$t("page.projects.newProject") +
        ` ${dayjs().format("DD/MM/YYYY, HH:mm:ss")}`;
    },
    setModalStatus(modalStatus) {
      this.modalStatus = modalStatus;
    },
    setProjectName(projectName) {
      this.projectName = projectName;
    },
    setProjectBranchId(projectBranchId) {
      this.projectBranchId = projectBranchId;
    },
  },
};
</script>

<style scoped>
.project__list__item {
  min-width: 270px;
}
.create-project-btn {
  font-size: 0.8rem;
  font-weight: 600;
}
@media screen and (max-width: 400px) {
  .project__list__item {
    min-width: unset;
  }
}
</style>
