var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-3 pt-3 p_b__3"},[(_vm.hasProjects)?_c('div',_vm._l((_vm.projectList),function(projectInfo,index){return _c('div',{key:index,staticClass:"py-5",class:{
        'bg-white': _vm.isActiveProjectList(projectInfo.status),
        'mx-n3': _vm.isActiveProjectList(projectInfo.status),
        'px-3': _vm.isActiveProjectList(projectInfo.status)
      }},[_c('div',{staticClass:"title__1"},[_vm._v(" "+_vm._s(_vm.$t(`page.dashboard.project.status.${projectInfo.label}.title`))+" ")]),_c('p',{staticClass:"text-muted subtitle__1"},[_vm._v(" "+_vm._s(_vm.$t(`page.dashboard.project.status.${projectInfo.label}.description`))+" ")]),(_vm.isListEmpty(projectInfo.list))?_c('div',{staticClass:"font-italic text-uppercase",domProps:{"innerHTML":_vm._s(
          _vm.$t(`page.dashboard.project.status.${projectInfo.label}.emptyList`)
        )}}):_c('b-row',{staticClass:"no-gutters"},_vm._l((projectInfo.list),function(project){return _c('b-col',{key:project.projectId,staticClass:"col-12 col-sm-6 col-md-4 col-lg-2 pr-0 pr-sm-3 mb-3 project__list__item"},[_c('project-dashboard-list-item',{attrs:{"project":project,"projectStatus":projectInfo.status}})],1)}),1)],1)}),0):_c('div',{staticClass:"mt-5 text-center"},[_c('h3',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t("page.projects.noPublishedProjects.title")))]),(_vm.isUserAllowedProjectHandling)?_c('div',[_c('h6',{staticClass:"mb-0",staticStyle:{"font-weight":"300"}},[_vm._v(" "+_vm._s(_vm.$t("page.projects.noPublishedProjects.subtitle-1"))+" ")]),_c('h6',{staticClass:"mb-0",staticStyle:{"font-weight":"300"}},[_vm._v(" "+_vm._s(_vm.$t("page.projects.noPublishedProjects.subtitle-2"))+" ")]),_c('b-button',{staticClass:"text-uppercase create-project-btn mt-5",attrs:{"size":"sm","variant":"red"},on:{"click":_vm.hanldeCreateProjectAction}},[_vm._v(" "+_vm._s(_vm.$t("button.createNewProject"))+" ")])],1):_vm._e()]),_c('new-project-modal',{attrs:{"modalStatus":_vm.modalStatus,"projectName":_vm.projectName,"projectType":_vm.projectType,"branchList":_vm.isMultiuserAdmin ? _vm.branchList : [],"projectBranchId":_vm.projectBranchId},on:{"setModalStatus":_vm.setModalStatus,"setProjectName":_vm.setProjectName,"setProjectBranchId":_vm.setProjectBranchId,"submit":_vm.checkEnv}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }