<template>
  <div>
    <b-card bg-variant="red" class="rounded-0">
      <b-row class="no-gutters justify-content-between">
        <b-col>
          <div class="text-white text-uppercase title__1">
            {{ $t("page.projects.yourProjects") }}
          </div>
        </b-col>
        <b-col class="col-auto" v-if="isUserAllowedProjectHandling">
          <b-button
            data-v-step="0"
            class="text-uppercase text-red bg-white border-0 btn__font-style"
            size="sm"
            @click="hanldeCreateProjectAction"
          >
            {{ $t("button.createNewProject") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <new-project-modal
      :modalStatus="modalStatus"
      :projectName="projectName"
      :projectType="projectType"
      :branchList="branchList"
      :projectBranchId="projectBranchId"
      @setModalStatus="setModalStatus"
      @setProjectName="setProjectName"
      @setProjectBranchId="setProjectBranchId"
      @submit="checkEnv"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";

import Utils from "./../../Utils";
import NewProjectModal from "./../NewProject/NewProjectModal";
export default {
  components: {
    NewProjectModal,
  },
  data() {
    return {
      visible: true,
      modalStatus: false,
      projectName: "",
      projectBranchId: "",
      projectType: "custom",
    };
  },
  computed: {
    userType() {
      return this.$store.getters["auth/getUserType"];
    },
    userSubType() {
      return this.$store.getters["auth/getUserSubType"];
    },
    isMultiUser() {
      return this.$store.getters["auth/isMultiUser"];
    },
    branchList() {
      // if custom multiuser admin users return branch list
      if (
        this.userType === "custom" &&
        this.isMultiUser &&
        this.userSubType === "admin"
      ) {
        return this.$store.getters["multiuser/getBranches"];
      }
      return [];
    },
    audienceProjectCategories() {
      return this.$store.getters["audience/getBrandCategoryOptions"];
    },
    isUserAllowedProjectHandling() {
      // custom + multiuser + researchers are not allowed to create, edit, delete a project
      if (
        this.userType === "custom" &&
        this.isMultiUser &&
        this.userSubType === "researcher"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    hanldeCreateProjectAction() {
      if (this.userType === Utils.USER_TYPE.REGULAR)
        this.$router.push({ name: "NewProject" });
      else if (this.userType === Utils.USER_TYPE.CUSTOMER) this.openModal();
    },
    async checkEnv() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        if (this.audienceProjectCategories.length === 0) {
          await this.$store.dispatch(
            "audience/retrieveProjectCategories",
            false
          );
        }

        this.createCutomerNewProject();
      } catch {
        this.$store.commit("error/SET_ERROR", {
          messageText: "page.newProject.error.createProject.message",
        });
      } finally {
        this.$store.dispatch("loader/close");
      }
    },
    createCutomerNewProject() {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store.commit(
        "wizard/SET_ACTIVE_PROJECT_PACKAGE",
        Utils.PROJECT_PACKAGE.CUSTOM
      );
      this.$store.commit("wizard/SET_ACTIVE_PROJECT_NAME", this.projectName);
      this.$store.commit("audience/RESET");
      this.$store.commit("question/RESET");
      this.$store
        .dispatch("project/createProjectByClientId", this.projectBranchId)
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    openModal(projectType) {
      this.modalStatus = true;
      this.projectName =
        this.projectType.toUpperCase() +
        " - " +
        this.$t("page.projects.newProject") +
        ` ${dayjs().format("DD/MM/YYYY, HH:mm:ss")}`;
    },
    setModalStatus(modalStatus) {
      this.modalStatus = modalStatus;
    },
    setProjectName(projectName) {
      this.projectName = projectName;
    },
    setProjectBranchId(projectBranchId) {
      this.projectBranchId = projectBranchId;
    },
  },
};
</script>

<style scoped>
.btn__font-style {
  /* font-family: ; */
  font-size: 0.8rem;
  font-weight: 600;
}
</style>
